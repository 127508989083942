import React from "react";
import SidebarRow from "./SidebarRow";
import "./Sidebar.css";

//icons
import PeopleIcon from "@material-ui/icons/People";
import PeopleOutlineIcon from "@material-ui/icons/PeopleOutline";
import OndemandVideoIcon from "@material-ui/icons/OndemandVideo";
import EventIcon from "@material-ui/icons/Event";

import FlagIcon from "@material-ui/icons/Flag";
import StorefrontIcon from "@material-ui/icons/Storefront";

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { useStateValue } from "./StateProvider";

function Sidebar() {
  const [{ user }, dispatch] = useStateValue();
  return (
    <div className="sidebar">
      <SidebarRow title={user.displayName} src={user.photoURL} />
      <SidebarRow Icon={PeopleIcon} title="Friends" />
      <SidebarRow Icon={FlagIcon} title="Pages" />
      <SidebarRow Icon={PeopleOutlineIcon} title="Groups" />
      <SidebarRow Icon={StorefrontIcon} title="Marketplace" />
      <SidebarRow Icon={OndemandVideoIcon} title="Watch" />
      <SidebarRow Icon={EventIcon} title="Events" />
      <SidebarRow Icon={ExpandMoreIcon} title="See more" />
    </div>
  );
}

export default Sidebar;
