import React from "react";
import Story from "./Story";
import "./StoryReel.css";

function StoryReel() {
  return (
    <div className="storyreel">
      <Story
        image="https://images.unsplash.com/photo-1626049758777-add6447b5eee?ixid=MnwxMjA3fDB8MHxlZGl0b3JpYWwtZmVlZHwxMHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60"
        userProfile="https://www.fakepersongenerator.com/Face/male/male20161086319962188.jpg"
        title="Paul J Augustin"
      />
      <Story
        image="https://images.unsplash.com/photo-1625910222078-3efd1c87f79c?ixid=MnwxMjA3fDB8MHx0b3BpYy1mZWVkfDJ8eEh4WVRNSExnT2N8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60"
        userProfile="https://www.fakepersongenerator.com/Face/female/female1022960250702.jpg"
        title="Eleanor M Villanueva"
      />
      <Story
        image="https://images.unsplash.com/photo-1556103255-4443dbae8e5a?ixid=MnwxMjA3fDB8MHxzZWFyY2h8M3x8cGhvdG9ncmFwaGVyfGVufDB8fDB8fA%3D%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60"
        userProfile="https://www.fakepersongenerator.com/Face/male/male20161086434727905.jpg"
        title="Martin M Shackelford"
      />
      <Story
        image="https://images.unsplash.com/photo-1549468939-a4760b08627a?ixid=MnwxMjA3fDB8MHx0b3BpYy1mZWVkfDE3fHJuU0tESHd3WVVrfHxlbnwwfHx8fA%3D%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60"
        userProfile="https://www.fakepersongenerator.com/Face/female/female20151024447235413.jpg"
        title="Helen J Hampton"
      />
      <Story
        image="https://images.unsplash.com/photo-1566751274024-1389c926e829?ixid=MnwxMjA3fDB8MHx0b3BpYy1mZWVkfDEyfFM0TUtMQXNCQjc0fHxlbnwwfHx8fA%3D%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60"
        userProfile="https://www.fakepersongenerator.com/Face/female/female20161025322365265.jpg"
        title="Nancy D Jensen"
      />
    </div>
  );
}

export default StoryReel;
