import "./Post.css";

import React from "react";
import { Avatar } from "@material-ui/core";
import ThumbUpAltIcon from "@material-ui/icons/ThumbUpAlt";
import SendIcon from "@material-ui/icons/Send";
import ChatBubbleOutlinedIcon from "@material-ui/icons/ChatBubbleOutlined";
function Post({ profilePic, username, timestamp, image, message }) {
  return (
    <div className="post">
      <div className="post__top">
        <Avatar src={profilePic} />
        <div className="post__info">
          <h3>{username}</h3>
          <p>{new Date(timestamp?.toDate()).toUTCString()}</p>
        </div>
      </div>
      <div className="post__bottom">
        <p>{message}</p>
      </div>
      <div className="post__image">
        <img src={image} alt="" />
      </div>
      <div className="post__options">
        <div className="post__option">
          <ThumbUpAltIcon />
          <p>Like</p>
        </div>
        <div className="post__option">
          <ChatBubbleOutlinedIcon />
          <p>Comment</p>
        </div>
        <div className="post__option">
          <SendIcon />
          <p>Share</p>
        </div>
      </div>
    </div>
  );
}

export default Post;
