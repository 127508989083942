// For Firebase JS SDK v7.20.0 and later, measurementId is optional
import firebase from "firebase";
const firebaseConfig = {
  apiKey: "AIzaSyDamdmhYqBp4ahrXYVlKzpKF8oe0goMR5I",
  authDomain: "facebook-clone-2ab5b.firebaseapp.com",
  projectId: "facebook-clone-2ab5b",
  storageBucket: "facebook-clone-2ab5b.appspot.com",
  messagingSenderId: "205685834006",
  appId: "1:205685834006:web:392ea22d546b3d1f711037",
  measurementId: "G-6KMYG56P6W",
};

const firebaseApp = firebase.initializeApp(firebaseConfig);
const db = firebaseApp.firestore();
const auth = firebase.auth();
const provider = new firebase.auth.GoogleAuthProvider();

export { auth, provider };
export default db;
