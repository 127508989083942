import "./Contacts.css";

import React from "react";
import VideoCallIcon from "@material-ui/icons/VideoCall";
import SearchIcon from "@material-ui/icons/Search";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";

import Online from "./Online";

function Contacts() {
  return (
    <div className="contacts">
      <div className="contacts__top">
        <p>Contacts</p>
        <div className="contacts__topright">
          <VideoCallIcon />
          <SearchIcon />
          <MoreHorizIcon />
        </div>
      </div>
      <div className="contacts__online">
        <Online
          profilePic="https://www.fakepersongenerator.com/Face/male/male1085080313843.jpg"
          username="Richard H Bader"
        />
        <Online
          profilePic="https://www.fakepersongenerator.com/Face/female/female20001023259409912.jpg"
          username="Denise A Bach"
        />
        <Online
          profilePic="https://www.fakepersongenerator.com/Face/male/male1084768476685.jpg"
          username="Phillip N Porter"
        />
      </div>
    </div>
  );
}

export default Contacts;
