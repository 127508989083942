import React from "react";
import { Avatar } from "@material-ui/core";
import "./Online.css";

function Online({ profilePic, username }) {
  return (
    <div class="online">
      <Avatar src={profilePic} />
      <p>{username}</p>
    </div>
  );
}

export default Online;
