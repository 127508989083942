import { Avatar } from "@material-ui/core";
import React, { useState } from "react";
import VideocamIcon from "@material-ui/icons/Videocam";
import PhotoLibraryIcon from "@material-ui/icons/PhotoLibrary";
import MoodIcon from "@material-ui/icons/Mood";
import "./MessageSender.css";
import { useStateValue } from "./StateProvider";
import db from "./firebase";
import firebase from "firebase";

function MessageSender() {
  const [{ user }, dispatch] = useStateValue();

  const [input, setInput] = useState("");
  const [imageURL, setImageURL] = useState("");
  const handleClick = (e) => {
    e.preventDefault();
    db.collection("posts").add({
      message: input,
      timestamp: firebase.firestore.FieldValue.serverTimestamp(),
      profilePic: user.photoURL,
      username: user.displayName,
      image: imageURL,
    });
    // resetting state to empty string
    setInput("");
    setImageURL("");
  };

  return (
    <div className="messageSender">
      <div className="messageSender__top">
        <Avatar src={user.photoURL} />
        <div class="messageSender__input">
          <form>
            <input
              value={input}
              onChange={(e) => setInput(e.target.value)}
              placeholder="What's on your mind?"
              required
            />
            <input
              value={imageURL}
              onChange={(e) => setImageURL(e.target.value)}
              placeholder="Image URL (Optional)"
            />
            <button
              onClick={handleClick}
              type="submit"
              className="messageSender__button"
            >
              submit
            </button>
          </form>
        </div>
      </div>
      <div className="messageSender__bottom">
        <div className="messageSender__options">
          <VideocamIcon style={{ color: "red" }} />
          <h3>Live Video</h3>
        </div>
        <div className="messageSender__options">
          <PhotoLibraryIcon style={{ color: "green" }} />
          <h3>Photo/Video</h3>
        </div>
        <div className="messageSender__options">
          <MoodIcon style={{ color: "#FFC300" }} />
          <h3>Feeling/Activity</h3>
        </div>
      </div>
    </div>
  );
}
export default MessageSender;
